<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item, index }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ total_rows - index }}</td>
                            <td>{{ item.TransactionCode }}</td>
                            <td>
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, ProductType: item.ProductType})">
                                    <div>
                                        {{ item.ProductType | display_value(product_type_list) }}
                                    </div>
                                    <template v-slot:input>
                                        <v-autocomplete clearable class="input-group--focused" label="Loại hàng" v-model="item.ProductType" :items="product_type_list" item-text="name" item-value="value"></v-autocomplete>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, Weight: item.Weight})">
                                    <div>
                                        {{ item.Weight | currency('', 2) }}
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.Weight" type="number" step=".1" label="Cân nặng" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, Length: item.Length, Width: item.Width, Height: item.Height})">
                                    <div>
                                        {{ item.Volume | currency('', 3) }} m³
                                        <v-divider></v-divider>
                                        {{ item.Length | currency('', 1)}} x {{ item.Width | currency('', 1)}} x {{ item.Height | currency('', 1)}}
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.Length" type="number" step=".1" label="Dài (cm)" single-line autofocus></v-text-field>
                                        <v-text-field v-model="item.Width" type="number" step=".1" label="Rộng (cm)" single-line autofocus></v-text-field>
                                        <v-text-field v-model="item.Height" type="number" step=".1" label="Cao (cm)" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td v-if="isDepositOrder">{{ item.StoreVNImportDate | display_date }}</td>
                            <td v-else>{{ item.StoreDestImportDate | display_date }}</td>
                            <td class="text-right">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, FeeShipVND: item.FeeShipVND})">
                                    <div>
                                        {{ item.FeeShipVND | currency }}
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.FeeShipVND" type="number" step="1" label="Phí khác" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="truncate">
                                <v-tooltip top max-width="250">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.StoreCNNote }}</span>
                                    </template>
                                    <span>{{ item.StoreCNNote }}</span>
                                </v-tooltip>
                            </td>
                            <td class="text-center">
                                <v-icon medium class="ma-1" color="red darken-2" @click="deleteItem(item)">fa-trash-alt</v-icon>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
        <v-dialog v-model="showDialogTrancode" fullscreen hide-overlay persistent transition="dialog-bottom-transition">
            <v-card>
            <v-toolbar dark color="oee-theme">
                <v-btn icon dark @click="showDialogTrancode=false">
                <v-icon>fa-times-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title>Nhập kho VN ngày {{currentTime | display_date('DD-MM-YYYY')}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-layout row wrap pa-3>
                <v-flex lg3 md3 sm3>
                    <v-layout row wrap>
                    <v-flex lg12 md12 sm12 xs12 pa-0>
                        <v-checkbox label="Nhập cân nặng" v-model="useWeight"></v-checkbox>
                        <v-checkbox label="Nhập kích thước" v-model="useVolume"></v-checkbox>
                        <v-checkbox label="Nhập kiện lô" v-model="useSameCode"></v-checkbox>
                    </v-flex>
                    </v-layout>
                    <v-layout row wrap>
                        <v-flex lg12 md12 sm12 xs12 pa-0>
                            <v-autocomplete class="input-group--focused" append-icon='' label="Loại hàng" v-model="selectedProductType" :items="product_type_list" item-text="name" item-value="value" @keyup.enter="focusNext" clearable></v-autocomplete>
                        </v-flex>
                        <StreamBarcodeReader @decode="onDecode" @loaded="onLoaded"  v-if="showDialogTrancode && isMobile && newTransaction.TransactionCode == ''"></StreamBarcodeReader>              
                        <v-flex lg12 md12 sm12 xs12 pa-0 v-if="(useVolume || useWeight) && (!useSameCode || newTransaction.Weight == 0)">
                            <v-text-field class="input-group--focused" ref="popup_trancode" autofocus label="Mã bill (enter)" v-model="newTransaction.TransactionCode" @keyup.enter="focusNext"></v-text-field>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pa-0 v-else>
                            <v-text-field class="input-group--focused" ref="popup_trancode" autofocus label="Mã bill (enter)" v-model="newTransaction.TransactionCode" @keyup.enter="saveNewTrancode"></v-text-field>
                        </v-flex>
                       <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume">
                           <h-currency-input class="input-group--focused" v-model="newTransaction.Length" label="Chiều dài (cm)" :decimal="1" @press_enter="focusNext"></h-currency-input>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume">
                            <h-currency-input class="input-group--focused" v-model="newTransaction.Width" label="Chiều rộng (cm)" :decimal="1" @press_enter="focusNext"></h-currency-input>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useVolume">
                            <h-currency-input v-if="useWeight" class="input-group--focused" v-model="newTransaction.Height" label="Chiều cao (cm)" :decimal="1" @press_enter="focusNext"></h-currency-input>
                            <h-currency-input v-else class="input-group--focused" v-model="newTransaction.Height" label="Chiều cao (cm)" :decimal="1" @press_enter="saveNewTrancode"></h-currency-input>
                        </v-flex>
                        <v-flex lg12 md12 sm12 xs12 pa-0 v-if="useWeight">
                            <h-currency-input class="input-group--focused" v-model="newTransaction.Weight" label="Cân nặng" :decimal="1" @press_enter="saveNewTrancode"></h-currency-input>
                        </v-flex>
                    </v-layout>
                </v-flex>
                <v-flex lg9 md9 sm9>
                    <v-layout pl-4 pr-2 pt-12>
                    <v-flex lg12 md12 sm12 xs12>
                        <v-data-table 
                        :headers="popup_headers"
                        :items="popup_items"
                        hide-default-footer
                        disable-pagination
                        :loading="loading"
                        :mobile-breakpoint="100"
                        >
                        <template v-slot:item="{ item, index }">
                            <tr>
                            <td>{{ popup_items.length - index }}</td>
                            <td>{{ item.TransactionCode }}</td>
                            <!-- <td>{{ item.TransactionCodeVN }}</td>
                            <td class="text-end">{{ item.ChinaWeight | currency('', 1)}}</td> -->
                            <td>{{ item.ProductType | display_value(product_type_list) }}</td>
                            <td class="text-right" v-if="useWeight">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, Weight: item.Weight})">
                                    <div>{{ item.Weight | currency('', 2) }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.Weight" type="number" step=".1" label="Cân nặng" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-right" v-if="useVolume">
                                <v-edit-dialog large lazy persistent @save="saveItem({ID: item.ID, Length: item.Length, Width: item.Width, Height: item.Height})">
                                    <div>
                                        {{ item.Volume | currency('', 2) }} m³
                                        <v-divider></v-divider>
                                        {{ item.Length | currency('', 1)}} x {{ item.Width | currency('', 1)}} x {{ item.Height | currency('', 1)}}
                                    </div>
                                    <template v-slot:input>
                                        <v-text-field v-model="item.Length" type="number" step=".1" label="Dài (cm)" single-line autofocus></v-text-field>
                                        <v-text-field v-model="item.Width" type="number" step=".1" label="Rộng (cm)" single-line autofocus></v-text-field>
                                        <v-text-field v-model="item.Height" type="number" step=".1" label="Cao (cm)" single-line autofocus></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            </tr>
                        </template>
                        </v-data-table>
                    </v-flex>
                    </v-layout>
                </v-flex>
                </v-layout>
            </v-card-text>
            </v-card>
        </v-dialog>
        <v-overlay :value="appLoading" z-index="999999">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-layout>
</v-container>
</template>

<script>
import {
    mapState
} from "vuex";
import configs from "@/configs";
import {isMobileAgent, focusNextElement, getCurrentTime} from "@/commons/utils";
import Filtering from "@/components/Filtering";
import { StreamBarcodeReader } from "vue-barcode-reader";
import _ from "lodash";

export default {
    components: {
        StreamBarcodeReader,
        "hpo-filter": Filtering
    },
    data() {
        return {
            isDepositOrder: configs.SYSTEM_TYPE != 'order',
            uniqueId: Date.now().toString(36) + Math.random().toString(36).substring(2),
            currentTime: getCurrentTime(),
            package_status: configs.PACKAGE_STATUS,
            package_status_enum: configs.PACKAGE_STATUS_ENUM,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "storeimporttransaction_list",
            filterConditions: [],
            quickFilterConditions: [],
            appLoading: false,
            showDialogTrancode: false,
            useVolume: false,
            useWeight: false,
            useInternalCode: false,
            useSameCode: false,
            focusNext: null,
            newTransaction: {
                TransactionCode: '',
                Status: configs.PACKAGE_STATUS_ENUM.DA_VE_VN,
                Width: 0,
                Length: 0,
                Height: 0,
                Weight: 0,
            },
            selectedProductType: null
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
        product_type_list() {
            let header_item = _.find(this.headers, { value: "ProductType" });
            if (header_item) {
                header_item.values = this.product_type_list;
            }
        },
        showDialogTrancode() {
            if (this.showDialogTrancode) {
                this.uniqueId = Date.now().toString(36) + Math.random().toString(36).substring(2);
                this.$store.commit("orderTransaction/setListImportVN", { data: []});
            }
        }
    },
    computed: {
        ...mapState({
            items: state => state.orderTransaction.all.data,
            total_rows: state => state.orderTransaction.all.total,
            loading: state => state.orderTransaction.loading,
            customers: state => state.customer.master.data,
            configuration: state => state.configuration.selected,
            popup_items: state => state.orderTransaction.listImportVN.data,
        }),
        isMobile: isMobileAgent,
        headers() {
            return [
                {
                    text: "STT",
                    value: "index"
                },
                {
                    text: "Mã bill",
                    value: "TransactionCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Loại hàng",
                    value: "ProductType",
                    filterable: true,
                    values: [],
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Cân nặng",
                    value: "Weight",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Kích thước",
                    value: "Volume",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Nhập kho VN",
                    value: configs.SYSTEM_TYPE == "order" ? "StoreDestImportDate" : "StoreVNImportDate",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Date"]
                },
                {
                    text: "Phí khác",
                    value: "FeeShipVND",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Ghi chú",
                    value: "StoreCNNote",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Thao tác",
                    value: ""
                }
                ];
        },
        popup_headers() {
            let tmp = [
                {
                    text: "STT",
                    value: "index"
                },
                {
                    text: "Mã bill",
                    value: "TransactionCode",
                    filterable: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                // {
                //     text: "Mã nội bộ",
                //     value: "TransactionCodeVN",
                //     filterable: true,
                //     dataType: configs.DATA_TYPE["String"]
                // },
                // {
                //     text: "Cân TQ",
                //     value: "ChinaWeight",
                //     filterable: true,
                //     dataType: configs.DATA_TYPE["Number"]
                // },
                {
                    text: "Loại SP",
                    value: "ProductType",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                }
            ];
            if(this.useWeight) {
                tmp.push({
                    text: "Cân nặng",
                    value: "Weight",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                })
            }
            if(this.useVolume) {
                tmp.push({
                    text: "Kích thước",
                    value: "Volume",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                })
            }
            return tmp;
        },
        adminRoles() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Manager) == configs.ROLE_TYPE.Manager);
        },
        storeVNRole() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.StoreVN) == configs.ROLE_TYPE.StoreVN);
        },
        moduleRole() {
            return this.$store.state.authentication.role.Module;
        },
        filter_param() {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            param.filter.push({
                column: "Status",
                value: configs.SYSTEM_TYPE == 'order' ? [configs.PACKAGE_STATUS_ENUM.DA_NHAP_KHO_PHAT, configs.PACKAGE_STATUS_ENUM.DA_VE_VN] : [configs.PACKAGE_STATUS_ENUM.DA_VE_VN],
                condition: "in"
            });
            param.filter.push({
                column: "BigPackageID",
                value: 0,
                condition: "equal"
            });
            
            return param;
        },
        product_type_list() {
            return this.configuration.ProductTypes ? JSON.parse(this.configuration.ProductTypes) : [];
        },
        popup_filter() {
            return [
                {
                    column: "GUID",
                    value: this.uniqueId,
                    condition: "s_equal"
                },
                {
                    column: "Status",
                    value: configs.SYSTEM_TYPE == 'order' ? [configs.PACKAGE_STATUS_ENUM.DA_NHAP_KHO_PHAT, configs.PACKAGE_STATUS_ENUM.DA_VE_VN] : [configs.PACKAGE_STATUS_ENUM.DA_VE_VN],
                    condition: "in"
                }
            ]
        },
    },
    methods: {
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        saveItem:_.debounce(
        function(item) {
            var data = {
               ...item
            };
            data.Type = "transaction";
            data.ModifiedBy = this.$store.state.authentication.user.username;
            data.Status = configs.PACKAGE_STATUS_ENUM.DA_VE_VN,
            delete data.CreatedDate;
            delete data.CreatedBy;
            this.$store.dispatch("orderTransaction/postOrderTransaction", data);
        }, 500, {leading: false, trailing: true}),
        // Only admin can view table, staff need search by code
        filter_data: function () {            
            this.$store.dispatch("orderTransaction/getList", this.filter_param);
            this.getListData();
            this.appLoading = false;
        },
        showTransactionDialog() {
            this.showDialogTrancode = true;
        },
        saveNewTrancode() {
            if(this.newTransaction.TransactionCode != '') {
                let payload = {
                    ...this.newTransaction,
                    GUID: this.uniqueId,
                    BigPackageId: 0
                }
                if(this.selectedProductType != null) {
                    payload.ProductType = this.selectedProductType;
                }
                this.$store.dispatch("bigPackage/addNewTrancode", {'data': payload});
            }
            this.newTransaction = {
                Status: configs.PACKAGE_STATUS_ENUM.DA_VE_VN,
                TransactionCode: '',
                Width: this.useSameCode ? this.newTransaction.Width : 0,
                Length: this.useSameCode ? this.newTransaction.Length : 0,
                Height: this.useSameCode ? this.newTransaction.Height : 0,
                Weight: this.useSameCode ? this.newTransaction.Weight : 0,
            };
            this.$nextTick(() => this.$refs.popup_trancode.focus());
        },
        gotoDetail: function (type, id, uid) {
            if (type) {
                let routeData = this.$router.resolve({
                    name: "DepositDetail",
                    params: {
                        Pid: id,
                        title: `Chi tiết đơn hàng (#${id})`
                    },
                    query: {
                        'uid': uid
                    }
                });
                window.open(routeData.href, "_blank");
            } else {
                let routeData = this.$router.resolve({
                    name: "OrderDetail",
                    params: {
                        Pid: id,
                        title: `Chi tiết đơn hàng (#${id})`
                    }
                });
                window.open(routeData.href, "_blank");
            }
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        getListData() {
            var param = {
                pagination: {...this.pagination, itemsPerPage: 10000},
                filter: this.popup_filter,
                quickFilter: []
            };
            this.$store.dispatch("orderTransaction/getListImportVN", param);
        },
        exportExcel() {
            this.$store.dispatch("orderTransaction/export", this.filter_param);
        },
        deleteItem(item) {
            window.getApp.$emit('REQUEST_DELETE_DATA', item.ID);
        },
    },
    created() {
        this.focusNext = focusNextElement;
    },
    mounted() {
        this.$store.dispatch("configuration/getDetail", 1);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("REQUEST_CREATE_DATA", this.showTransactionDialog);
        this.$root.$on("REQUEST_OTHER_ACTION", this.exportExcel);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("orderTransaction/postOrderTransaction", {
                'ID': id,
                'Type': "transaction",
                'ModifiedBy': this.$store.state.authentication.user.username,
                'Status': configs.PACKAGE_STATUS_ENUM.DANG_VE_VN,
            });
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("REQUEST_CREATE_DATA", this.showTransactionDialog);
        this.$root.$off("REQUEST_OTHER_ACTION", this.exportExcel);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
